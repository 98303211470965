var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    class: "".concat(!_vm.resCalcPrice ? 'pt-1' : ''),
    attrs: {
      "no-body": ""
    }
  }, [_vm.selectedTrips && !_vm.resCalcPrice && _vm.getSearchFlightArray ? [_vm._l(_vm.getSearchFlightArray, function (dataSearchTrip, indexSearchTrip) {
    return _c('BCard', {
      key: indexSearchTrip,
      staticClass: "border mx-50",
      attrs: {
        "header-bg-variant": "light-secondary",
        "header-class": "font-weight-bolder py-50",
        "no-body": ""
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('span', {
            staticClass: "text-body"
          }, [_vm._v(" " + _vm._s(['VN'].includes(_vm.airlineSearch) ? 'Giá tạm tính:' : 'Thông tin giá: ') + " " + _vm._s("".concat(dataSearchTrip.startPoint, "-").concat(dataSearchTrip.endPoint, " ").concat(dataSearchTrip.departDate ? " ".concat(_vm.convertISODateTime(dataSearchTrip.departDate).dayAndMonth) : '')) + " ")])];
        },
        proxy: true
      }], null, true)
    }, [_vm.selectedTrips[indexSearchTrip] && _vm.selectedTrips[indexSearchTrip].every(Boolean) && _vm.dataTrips[indexSearchTrip] ? [(['VN'].includes(_vm.airlineSearch) ? _vm.dataTrips[indexSearchTrip].isDomestic : true) ? _c('div', [_c('BTable', {
      staticClass: "rounded mt-50",
      attrs: {
        "small": "",
        "responsive": "",
        "items": _vm.getDraftPriceTripSelected(_vm.selectedTrips[indexSearchTrip]),
        "head-variant": "success",
        "thead-tr-class": "text-nowrap",
        "tbody-tr-class": "text-nowrap text-right",
        "fields": ['passenger', 'net', 'tax', 'total']
      },
      scopedSlots: _vm._u([_vm._l(['passenger', 'net', 'tax', 'total'], function (column, indexColumn) {
        return {
          key: "head(".concat(column, ")"),
          fn: function fn(data) {
            return [_c('div', {
              key: indexColumn,
              class: "".concat(indexColumn === 0 ? 'text-center' : 'text-right')
            }, [_c('span', [_vm._v(_vm._s(_vm.$t("reservation.".concat(data.column))))])])];
          }
        };
      }), {
        key: "cell(passenger)",
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_c('div', {
            staticClass: "text-center"
          }, [_c('div', {
            staticClass: "font-weight-bolder text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("flight.".concat(_vm.capitalize(item.paxType)))) + " x1 ")]), _c('div', {
            staticClass: "d-flex flex-column flex-nowrap"
          }, [_vm._l(item.fareBasisCode, function (fareCode, indexF) {
            return [fareCode ? _c('small', {
              key: indexF,
              staticClass: "fw-700"
            }, [_vm._v(" (" + _vm._s(fareCode) + ") ")]) : _vm._e()];
          })], 2)])];
        }
      }, {
        key: "cell(net)",
        fn: function fn(_ref2) {
          var item = _ref2.item;
          return [_c('span', {
            staticClass: "font-weight-bolder"
          }, [_vm._v(_vm._s(Number(item.net) === 0 ? '-' : item.net))])];
        }
      }, {
        key: "cell(tax)",
        fn: function fn(data) {
          return [_c('span', {
            staticClass: "font-weight-bolder",
            attrs: {
              "id": "pax_tax_".concat(data.index, "_").concat(data.item.paxType, "_").concat(data.item.fareBasisCode, "_").concat(indexSearchTrip)
            }
          }, [_vm._v(" " + _vm._s(Number(data.item.tax) === 0 ? '-' : data.item.tax) + " ")]), Number(data.item.tax) !== 0 ? _c('BTooltip', {
            attrs: {
              "id": "tooltip-pax_tax_".concat(data.index, "_").concat(data.item.paxType, "_").concat(data.item.fareBasisCode, "_").concat(indexSearchTrip),
              "target": "pax_tax_".concat(data.index, "_").concat(data.item.paxType, "_").concat(data.item.fareBasisCode, "_").concat(indexSearchTrip),
              "triggers": "hover",
              "variant": "info",
              "placement": "top",
              "boundary": "window"
            }
          }, [_c('div', {
            class: "font-italic text-white ".concat(_vm.isMobileView ? 'font-small-1' : 'font-small-3')
          }, [_vm._v(" (" + _vm._s(_vm.$t('flight.fareNotIncludeServiceFee')) + ") ")])]) : _vm._e()];
        }
      }, {
        key: "cell(total)",
        fn: function fn(data) {
          return [_c('span', {
            staticClass: "font-weight-bolder",
            attrs: {
              "id": "pax_total_".concat(data.index, "_").concat(data.item.paxType, "_").concat(data.item.fareBasisCode, "_").concat(indexSearchTrip)
            }
          }, [_vm._v(" " + _vm._s(data.item.total) + " ")]), _c('BTooltip', {
            attrs: {
              "id": "tooltip-pax_total_".concat(data.index, "_").concat(data.item.paxType, "_").concat(data.item.fareBasisCode, "_").concat(indexSearchTrip),
              "target": "pax_total_".concat(data.index, "_").concat(data.item.paxType, "_").concat(data.item.fareBasisCode, "_").concat(indexSearchTrip),
              "triggers": "hover",
              "variant": "info",
              "placement": "top",
              "boundary": "window"
            }
          }, [_c('div', {
            class: "font-italic text-white ".concat(_vm.isMobileView ? 'font-small-1' : 'font-small-3')
          }, [_vm._v(" (" + _vm._s(_vm.$t('flight.fareNotIncludeServiceFee')) + ") ")])])];
        }
      }], null, true)
    }), _c('BAlert', {
      staticClass: "mb-75 py-50 px-1 text-center",
      attrs: {
        "show": ['VN'].includes(_vm.airlineSearch),
        "variant": "danger"
      }
    }, [_c('span', {
      staticClass: "text-danger fw-700 font-italic"
    }, [_vm._v(_vm._s(_vm.$t("flight['Please calculate the price to get the exact price']")))])])], 1) : _c('div', {
      staticClass: "ml-1 mt-1 mb-2 font-italic text-danger font-weight-bolder d-block"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.plsCalcFlight')) + " ")])] : _c('div', {
      staticClass: "ml-1 mb-50 font-italic text-danger font-weight-bolder d-block"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.notSelectedFlights')) + " ")])], 2);
  }), ['VU'].includes(_vm.airlineSearch) && _vm.isSelectedAll ? _c('BCard', {
    staticClass: "border mx-50 mt-2",
    attrs: {
      "header-bg-variant": "light-success",
      "header-class": "font-weight-bolder py-50",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-around align-items-center font-medium-2"
        }, [_c('span', {
          staticClass: "text-body"
        }, [_vm._v(" " + _vm._s(_vm.$t("flight['Total ticket price']")) + " ")]), _c('span', {
          staticClass: "text-danger fw-700",
          class: _vm.isMobileView ? 'font-medium-2' : 'font-medium-3'
        }, [_vm._v(" " + _vm._s(_vm.getTotalPriceTripSelectVU(_vm.selectedTrips).totalPriceAllPax) + " ")])])];
      },
      proxy: true
    }], null, false, 3510848078)
  }) : _vm._e()] : _vm._e(), _vm.resCalcPrice ? _c('BCard', {
    staticClass: "shadow mb-0",
    attrs: {
      "header-bg-variant": "light-success",
      "header-class": "font-weight-bolder font-medium-2 py-50",
      "body-class": "p-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-airline"
        }, [_vm._v(_vm._s(_vm.$t("flight['Pricing information']")) + ":")])];
      },
      proxy: true
    }], null, false, 1492757356)
  }, [[_vm._l(_vm.resCalcPrice, function (trip, indexTrip) {
    return _c('BCard', {
      key: indexTrip,
      staticClass: "border mb-2",
      attrs: {
        "header-bg-variant": "light-info",
        "header-class": "font-weight-bolder text-airline font-medium-2 py-50",
        "no-body": ""
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('reservation.itinerary')) + " ")]), _vm.storeSearchFlight && !['RT'].includes(_vm.storeSearchFlight.type) ? _c('span', [_vm._v(" #" + _vm._s(trip.tripIndex + 1) + " ")]) : _vm._e(), _vm._v(": "), _c('span', [_vm._v(_vm._s(trip.flights))])])];
        },
        proxy: true
      }], null, true)
    }, [['success'].includes(trip.status) ? [_c('BTable', {
      staticClass: "mb-0 mt-25",
      attrs: {
        "small": "",
        "responsive": "",
        "items": trip.res,
        "thead-tr-class": "text-nowrap",
        "tbody-tr-class": "text-nowrap text-right",
        "fields": ['passenger', 'net', 'tax', 'total']
      },
      scopedSlots: _vm._u([_vm._l(['passenger', 'net', 'tax', 'total'], function (column, indexColumn) {
        return {
          key: "head(".concat(column, ")"),
          fn: function fn(data) {
            return [_c('div', {
              key: indexColumn,
              class: "".concat(indexColumn === 0 ? 'text-center' : 'text-right')
            }, [_c('span', [_vm._v(_vm._s(_vm.$t("reservation.".concat(data.column))))])])];
          }
        };
      }), {
        key: "cell(passenger)",
        fn: function fn(_ref3) {
          var item = _ref3.item;
          return [_c('div', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(_vm.$t("flight.".concat(_vm.capitalize(item.passengerRequested.paxType)))) + " x1 ")]), _c('div', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(" " + _vm._s(item.fareInfos.map(function (f) {
            return f.fareBasisCode;
          }).join(', ')) + " ")])];
        }
      }, {
        key: "cell(net)",
        fn: function fn(_ref4) {
          var item = _ref4.item;
          return [_c('span', {
            staticClass: "font-weight-bolder"
          }, [_vm._v(_vm._s(_vm.formatCurrency(item.basePrice)))])];
        }
      }, {
        key: "cell(tax)",
        fn: function fn(data) {
          return [_c('span', {
            staticClass: "font-weight-bolder",
            attrs: {
              "id": "pax_tax_".concat(data.index, "_").concat(data.item.basePrice)
            }
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.finalTotalTaxes)) + " ")])];
        }
      }, {
        key: "cell(total)",
        fn: function fn(data) {
          return [_c('span', {
            staticClass: "font-weight-bolder text-airline",
            attrs: {
              "id": "pax_total_".concat(data.index, "_").concat(data.item.finalTotalPrices)
            }
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.finalTotalPrices)) + " ")])];
        }
      }], null, true)
    })] : _c('div', {
      staticClass: "ml-1 py-50 font-italic text-danger"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.errCalcFlight')) + " ")])], 2);
  }), _vm.resCalcPrice.every(function (t) {
    return t.status === 'success';
  }) ? _c('BAlert', {
    staticClass: "px-1 py-1 mt-1 mb-0 font-weight-bold",
    attrs: {
      "variant": "secondary",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "mb-50 font-medium-2 text-danger fw-700"
  }, [_vm._v(" TỔNG TIỀN VÉ ")]), _c('BTable', {
    staticClass: "rounded border",
    attrs: {
      "small": "",
      "responsive": "",
      "items": _vm.getTotalAllTripPriceCalc(_vm.resCalcPrice),
      "head-variant": "success",
      "thead-tr-class": "text-nowrap text-right",
      "tbody-tr-class": "text-nowrap text-right",
      "fields": ['totalNet', 'totalTax', 'totalPrice']
    },
    scopedSlots: _vm._u([_vm._l(['totalNet', 'totalTax', 'totalPrice'], function (column, indexColumn) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: indexColumn
          }, [_c('span', [_vm._v(_vm._s(_vm.$t("flight.searchClass.".concat(data.column))))])])];
        }
      };
    }), {
      key: "cell(totalNet)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(_vm._s(item.net))])];
      }
    }, {
      key: "cell(totalTax)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v(_vm._s(item.tax))])];
      }
    }, {
      key: "cell(totalPrice)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "text-danger fw-700"
        }, [_vm._v(_vm._s(item.total))])];
      }
    }], null, true)
  })], 1) : _vm._e()]], 2) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }